import { ReactElement, ReactNode } from 'react'
import produce from 'immer'
import create from 'zustand'
import createContext from 'zustand/context'
import { combine } from 'zustand/middleware'

export type SnappingFeaturesEnabled = {
  snapToAngle: boolean
  snapOrthogonal: boolean
  snapToCornersAndEdges: boolean
  snapToTapelineCenters: boolean
}

interface Props {
  children: ReactNode
}

interface ControlStoreInitialState {
  gridEnabled: boolean
  axesEnabled: boolean
  componentDetailEnabled: boolean
  snappingFeaturesEnabled: SnappingFeaturesEnabled
  orthographicDrawMode: boolean
  selectionMessage: string | null
  selectionModeKey: string | null
  clickListeners: Map<
    string,
    { listener: ModelClickListener; isExclusive: boolean; enableOnShift: boolean }
  >
  hoverListeners: ModelHoverListener[][]
  actionMode: ActionMode
  hiddenElementIds: Set<string>
  filterPortalElement: HTMLDivElement | null
  widgetPortalElement: HTMLDivElement | null
  isSceneBlocked: boolean

  isDrawingActive: boolean

  isDrawerExpanded: boolean

  isAnchorCalculationMode: boolean
  isBottomDrawerExpanded: boolean

  // SNAPPING

  snapToCornersAndEdges: boolean
  snapToAngles: boolean
  snapOrthogonal: boolean
  snapToTapelineCenters: boolean
}

type SelectionModeConfig =
  | {
      message: string
      key?: 'default' | string
    }
  | undefined

interface ControlStoreType extends ControlStoreInitialState {
  toggleGridEnabled: () => void
  toggleAxesEnabled: () => void
  toggleComponentDetailEnabled: () => void
  toggleSelectionMode: (config?: SelectionModeConfig) => void
  setSnappingFeaturesEnabled: (snappingFeaturesEnabled: SnappingFeaturesEnabled) => void
  setOrthographicDrawMode: (enabled: boolean) => void
  setClickListener: (
    id: string,
    listener: ModelClickListener,
    isExclusive?: boolean,
    enableOnShift?: boolean,
  ) => void
  removeClickListener: (id: string) => void
  setHoverListeners: (listeners: ModelHoverListener[]) => void
  removeHoverListeners: (listeners: ModelHoverListener[]) => void
  setActionMode: (mode: ActionMode | null) => void
  addHiddenElementIds: (ids: string[]) => void
  removeHiddenElementIds: (ids: string[]) => void
  setAllElementsVisible: () => void
  setFilterPortalElement: (ref: HTMLDivElement) => void
  setWidgetPortalElement: (ref: HTMLDivElement) => void
  setSceneBlocked: (isBlocked: boolean) => void

  setIsDrawingActive: (active?: boolean) => void
  setIsDrawerExpanded: (isDrawerExpanded: boolean) => void
  setIsAnchorCalculationMode: (isAnchorCalculationMode: boolean) => void
  setIsBottomDrawerExpanded: (isBottomDrawerExpanded: boolean) => void

  // SNAPPING

  setSnapToCornersAndEdges: (snapToCornersAndEdges?: boolean) => void
  setSnapToAngles: (setSnapToAngles?: boolean) => void
  setSnapOrthogonal: (snapOrthogonal?: boolean) => void
  setSnapToTapelineCenters: (snapToTapelineCenters?: boolean) => void
}

const initialState: ControlStoreInitialState = {
  actionMode: 'select',
  orthographicDrawMode: false,
  snappingFeaturesEnabled: {
    snapOrthogonal: true,
    snapToAngle: true,
    snapToCornersAndEdges: true,
    snapToTapelineCenters: true,
  },
  snapToCornersAndEdges: true,
  snapToAngles: false,
  snapOrthogonal: false,
  snapToTapelineCenters: true,

  selectionMessage: null,
  selectionModeKey: null,
  clickListeners: new Map(),
  hoverListeners: [],
  gridEnabled: false,
  axesEnabled: false,
  hiddenElementIds: new Set(),
  filterPortalElement: null,
  widgetPortalElement: null,
  isSceneBlocked: false,

  componentDetailEnabled: false,

  isDrawingActive: false,
  isDrawerExpanded: true,

  isAnchorCalculationMode: false,
  isBottomDrawerExpanded: false,
}

const createStore = () =>
  create(
    combine(
      {
        ...initialState,
      },
      set => ({
        setSceneBlocked: (isBlocked: boolean) =>
          set(
            produce(state => {
              state.isSceneBlocked = isBlocked
            }),
          ),

        removeHiddenElementIds: (ids: string[]) =>
          set(
            produce(state => {
              ids.forEach(id => state.hiddenElementIds.delete(id))
            }),
          ),

        addHiddenElementIds: (ids: string[]) =>
          set(
            produce(state => {
              state.hiddenElementIds = new Set([...state.hiddenElementIds, ...ids])
            }),
          ),

        setAllElementsVisible: () =>
          set(
            produce(state => {
              state.hiddenElementIds = new Set()
            }),
          ),

        setActionMode: (actionMode: ActionMode | null) =>
          set(() => ({ actionMode: actionMode || 'select' })),
        toggleGridEnabled: () => set(({ gridEnabled }) => ({ gridEnabled: !gridEnabled })),

        toggleAxesEnabled: () => set(({ axesEnabled }) => ({ axesEnabled: !axesEnabled })),

        toggleComponentDetailEnabled: () =>
          set(({ componentDetailEnabled }) => ({
            componentDetailEnabled: !componentDetailEnabled,
          })),

        toggleSelectionMode: (config?: SelectionModeConfig) =>
          set(
            produce(state => {
              state.selectionMessage = config?.message || null
              state.selectionModeKey = config ? config.key || 'default' : null
            }),
          ),

        setClickListener: (
          id: string,
          listener: ModelClickListener,
          isExclusive = false,
          enableOnShift = false,
        ) =>
          set(
            produce(state => {
              state.clickListeners.set(id, {
                listener,
                isExclusive,
                enableOnShift,
              })
            }),
          ),

        setOrthographicDrawMode: (enabled: boolean) =>
          set(
            produce(state => {
              state.orthographicDrawMode = enabled
            }),
          ),

        setIsDrawerExpanded: (isDrawerExpanded: boolean) => set({ isDrawerExpanded }),

        setIsAnchorCalculationMode: (isAnchorCalculationMode: boolean) =>
          set({ isAnchorCalculationMode }),
        setIsBottomDrawerExpanded: (isBottomDrawerExpanded: boolean) =>
          set({ isBottomDrawerExpanded }),

        removeClickListener: (id: string) =>
          set(
            produce(state => {
              state.clickListeners.delete(id)
            }),
          ),

        setHoverListeners: (listeners: ModelHoverListener[]) =>
          set(
            produce(state => {
              state.hoverListeners.push(listeners)
            }),
          ),

        removeHoverListeners: (listeners: ModelHoverListener[]) =>
          set(
            produce(state => {
              state.hoverListeners.splice(state.hoverListeners.indexOf(listeners), 1)
            }),
          ),

        setFilterPortalElement: (ref: HTMLDivElement) =>
          set(
            produce(state => {
              state.filterPortalElement = ref
            }),
          ),

        setWidgetPortalElement: (ref: HTMLDivElement) =>
          set(
            produce(state => {
              state.widgetPortalElement = ref
            }),
          ),

        setIsDrawingActive: (isDrawingActive?: boolean) => {
          set(
            produce(state => {
              state.isDrawingActive = isDrawingActive
            }),
          )
        },

        // SNAPPING

        setSnappingFeaturesEnabled: (snappingFeaturesEnabled: SnappingFeaturesEnabled) =>
          set({ snappingFeaturesEnabled }),

        setSnapToCornersAndEdges: (snapToCornersAndEdges?: boolean) => {
          set(
            produce(state => {
              state.snapToCornersAndEdges = snapToCornersAndEdges
            }),
          )
        },

        setSnapToAngles: (setSnapToAngles?: boolean) => {
          set(
            produce(state => {
              state.snapToAngles = setSnapToAngles
            }),
          )
        },

        setSnapOrthogonal: (snapOrthogonal?: boolean) => {
          set(
            produce(state => {
              state.snapOrthogonal = snapOrthogonal
            }),
          )
        },

        setSnapToTapelineCenters: (snapToTapelineCenters?: boolean) => {
          set(
            produce(state => {
              state.snapToTapelineCenters = snapToTapelineCenters
            }),
          )
        },
      }),
    ),
  )

const { Provider, useStore: useControlStore } = createContext<ControlStoreType>()

const ControlStoreProvider = ({ children }: Props): ReactElement => (
  <Provider createStore={createStore}>{children}</Provider>
)

export { ControlStoreProvider, useControlStore }
