import { ReactElement, useCallback, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { getElementAssemblyType } from '@editorUtils'
import { AxiosError } from 'axios'
import { includes, without } from 'lodash-es'
import { enqueueSnackbar } from 'notistack'
import { useQueryParams, ArrayParam, StringParam } from 'use-query-params'
import { LoadingButton } from '@mui/lab'
import { Divider, Stack, Typography } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { LayoutDrawer as Layout, Drawer, Box } from '@ui/structure'
import { useControlStore } from '@editorStores'
import { useModelClickListeners, useHighlightFromParams, useTypeInteraction } from '@editorHooks'
import { AssemblyAssignmentForm, SceneControlled } from '@editorComponents'
import {
  getAssemblyAssignment,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
} from '@queries'
import { restoreLintelCSOnWalls, restoreRipCSOnWalls } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'

const AssemblySelection = (): ReactElement => {
  const queryClient = useQueryClient()
  const { projectId } = useParams()

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)

  useEffect(() => {
    queryClient.invalidateQueries(getAssemblyAssignment.getKey(projectId))
  }, [projectId, queryClient])

  const [{ elements: selectedElements, elementType }, setQuery] = useQueryParams({
    elements: ArrayParam,
    elementType: StringParam,
  })
  const reset = useCallback(() => setQuery({ elements: undefined, elementType: undefined }), [])

  useModelClickListeners(
    event => {
      const { name: guid } = event.object
      const { elementType } = event.object.userData
      const selected = selectedElements || []
      const elements = includes(selected, guid) ? without(selected, guid) : [...selected, guid]

      setQuery({
        elementType: elements.length ? elementType : undefined,
        elements,
      })
    },
    [selectedElements],
  )
  useHighlightFromParams(['elements'])
  useTypeInteraction((elementType as ElementTypes) || 'all')

  useHotkeys('esc', reset, { enabled: !isTapelineDrawing && actionMode !== 'hide' }, [reset])

  const { mutateAsync: restoreRipCS, isLoading: isRestoringRipCS } = useMutation(
    (element_guids: string[]) => {
      return restoreRipCSOnWalls.request(projectId as string, element_guids)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        await queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        enqueueSnackbar('Querschnitte von Rippen erfolgreich aus Aufbau übernommen', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
      },
    },
  )
  const { mutateAsync: restoreLintelCS, isLoading: isRestoringLintelCS } = useMutation(
    (element_guids: string[]) => {
      return restoreLintelCSOnWalls.request(projectId as string, element_guids)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        await queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        enqueueSnackbar('Querschnitte von Stürzen erfolgreich aus Aufbau übernommen', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
      },
    },
  )

  return (
    <Layout>
      <SceneControlled />
      <Drawer>
        <>
          {!selectedElements?.length && (
            <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
              <Typography textAlign="center" variant="h6">
                Ausgewählt: 0
              </Typography>
              <Typography mb={1}>Wählen Sie eine Wand aus, um Aufbauten zu zuweisen</Typography>
            </Box>
          )}
          {selectedElements?.length && elementType && (
            <Stack spacing={2}>
              <AssemblyAssignmentForm
                elementType={getElementAssemblyType(elementType as ElementTypes)}
                selectedElements={selectedElements as string[]}
                onClose={reset}
              />
              <>
                {['outer_walls', 'inner_walls'].includes(elementType) && (
                  <>
                    <Divider />
                    <Typography>Querschnitte aus Aufbaudefinition übernehmen</Typography>
                    <Stack spacing={2} direction="row">
                      <LoadingButton
                        loading={isRestoringRipCS}
                        type="submit"
                        variant="contained"
                        size="small"
                        data-cy="restore-rip-cs-submit"
                        onClick={() => restoreRipCS(selectedElements as string[])}
                      >
                        Standard-QS für Rippen wiederherstellen
                      </LoadingButton>
                      <LoadingButton
                        loading={isRestoringLintelCS}
                        type="submit"
                        variant="contained"
                        size="small"
                        data-cy="restore-lintel-cs-submit"
                        onClick={() => restoreLintelCS(selectedElements as string[])}
                      >
                        Standard-QS für Stürze wiederherstellen
                      </LoadingButton>
                    </Stack>
                  </>
                )}
              </>
            </Stack>
          )}
        </>
      </Drawer>
    </Layout>
  )
}

export default AssemblySelection
