import { ReactElement } from 'react'
import { useController } from 'react-hook-form'
import { find, isFunction, filter } from 'lodash-es'
import Add from '@mui/icons-material/Add'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import {
  Autocomplete as MuiAutocomplete,
  TextField,
  TextFieldProps,
  SxProps,
  MenuItem,
  IconButton,
  Stack,
} from '@mui/material'
import FormControl from '../FormControl'

interface Option {
  value: string | number
  label: string
}

interface Props {
  name: string
  options: Option[]
  textFieldProps?: TextFieldProps
  sx?: SxProps
  onEdit?: (option: Option) => void
  onAdd?: () => void
  onDuplicate?: (option: Option) => void
  onAddLabel?: string
  disablePortal?: boolean
  dataCy?: string
}

const Autocomplete = ({
  options,
  textFieldProps = {},
  sx = {},
  name,
  onEdit,
  onAdd,
  onDuplicate,
  onAddLabel,
  disablePortal = true,
  dataCy,
}: Props): ReactElement => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
  })

  const addOptions = isFunction(onAdd) ? [{ value: 'add-new', label: onAddLabel || '' }] : []

  return (
    <FormControl error={error?.message}>
      <MuiAutocomplete
        key={value}
        data-cy={dataCy}
        disablePortal={disablePortal}
        onChange={(event, newValue) => {
          onChange(newValue?.value)
        }}
        onBlur={onBlur}
        options={options}
        getOptionLabel={option => option.label}
        value={find(options, ['value', value])}
        filterOptions={(options, { getOptionLabel, inputValue }) => {
          const matches = filter(options, option => {
            return getOptionLabel(option).includes(inputValue)
          })

          return [...matches, ...addOptions]
        }}
        renderOption={({ className, ...props }, option) =>
          option.value === 'add-new' ? (
            <MenuItem
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={onAdd}
            >
              <IconButton sx={{ padding: theme => theme.spacing(0.5) }}>
                <Add fontSize="small" />
              </IconButton>
              <span>{option.label}</span>
            </MenuItem>
          ) : (
            <MenuItem
              {...props}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span>{option.label}</span>
              <Stack direction="row">
                {isFunction(onEdit) && (
                  <IconButton
                    sx={{ padding: theme => theme.spacing(0.5) }}
                    onClick={event => {
                      event.stopPropagation()
                      onEdit(option)
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
                {isFunction(onDuplicate) && (
                  <IconButton
                    sx={{ padding: theme => theme.spacing(0.5) }}
                    onClick={event => {
                      event.stopPropagation()
                      onDuplicate(option)
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                )}
              </Stack>
            </MenuItem>
          )
        }
        sx={sx}
        renderInput={params => (
          <TextField
            {...params}
            {...{
              fullWidth: true,
              size: 'small',
              ...textFieldProps,
            }}
            name={name}
            autoFocus
          />
        )}
      />
    </FormControl>
  )
}

export default Autocomplete
