import { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { getElementCrossSectionAssignment, getMemberCheckSettings } from '@queries'
import { updateElementCrossSectionAssignment } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import SingleCSForm from './components/SingleCSForm'

interface Props {
  selectedElement: string
  elementType: ElementTypes
  invalidateResults?: boolean
  onSave?: () => void
  warning?: string
  alternateMutation?: {
    mutateAsync: (data: CrossSection) => Promise<unknown>
    isLoading: boolean
  }
  disableIfDirty?: boolean
}

const SingleElementCSForm = ({
  selectedElement,
  elementType,
  invalidateResults = true,
  onSave = undefined,
  warning,
  alternateMutation,
  disableIfDirty = true,
}: Props): ReactElement => {
  const { projectId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateResultsQueries = useResultsInvalidation()

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  const standardRipChecks = useResultsStore(state => state.standardRipChecks)

  const selectedElementCrossSection = useMemo(() => {
    if (!selectedElement) return undefined
    if (
      elementType === 'beams' ||
      elementType === 'purlins' ||
      elementType === 'columns' ||
      elementType === 'rips' ||
      elementType === 'lintels'
    ) {
      return find(elementCrossSectionAssignment, ['element_guid', selectedElement])?.element_cs
    } else if (elementType === 'standard_rips') {
      const check = find(standardRipChecks, check => {
        return check.standard_rip_member.guid === selectedElement
      })
      return check?.standard_rip_member.cross_section
    }
  }, [selectedElement, elementType, elementCrossSectionAssignment, standardRipChecks])

  const { mutateAsync, isLoading } = useMutation(
    (data: CrossSection) => {
      const elementCS = { element_guid: selectedElement, element_cs: data }
      return updateElementCrossSectionAssignment.request(projectId as string, elementCS)
    },
    {
      onMutate: async (data: CrossSection) => {
        // Update the local state directly so no refetch is required
        setSingleCrossSection(selectedElement, data)
        if (onSave) {
          onSave()
        }
      },
      onSuccess: async () => {
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))

        if (invalidateResults) invalidateResultsQueries(projectId as string)
        else
          enqueueSnackbar(
            'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
            {
              variant: 'warning',
            },
          )

        enqueueSnackbar('Querschnitt-Zuweisung erfolgreich gespeichert', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        // Refetch onError to ensure local state is in sync with backend again
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
      },
    },
  )

  return (
    <SingleCSForm
      mutateAsync={alternateMutation?.mutateAsync || mutateAsync}
      isLoading={alternateMutation?.isLoading || isLoading}
      selectedElement={selectedElement}
      elementType={elementType}
      defaultCS={selectedElementCrossSection}
      isDisabled={elementType === 'standard_rips'}
      warning={warning}
      disableIfDirty={disableIfDirty}
    />
  )
}

export default SingleElementCSForm
